import get from 'lodash/get';

import {
    submitActionEventToGtm,
    submitPageViewEventToGtm,
    notifyToSlack
} from 'common/gtmScripts';
import { submitActionEvent } from 'common/crmScripts';

import { ORDER_STEPS } from './constants';
import { requestData, receiveData } from '../api/actions';
import {
    initOrder,
    fetchLanguages,
    getOrder,
    postFile,
    getFile,
    getAssetFile,
    postAssetFile,
    deleteAssetFile,
    postFileSettings,
    postInstanceQuote,
    postLanguageSettings,
    postOrderInformation,
    postCurrencySettings,
    getQuoteFile
} from 'services/order';

import * as payApi from 'services/payment';

import { ORDER, COMMON, PAYMENT } from './actionConstants';
import { MAX_UPLOAD_FILE_SIZE } from './constants';

export function onStepNext() {
    return {
        type: ORDER.ON_NEXT
    };
}

export function onStepBack() {
    return {
        type: ORDER.ON_BACK
    };
}

export function removeFile() {
    return {
        type: ORDER.REMOVE_FILE
    };
}

export function closeMessage() {
    return {
        type: ORDER.CLOSE_MESSAGE
    };
}

export function refreshSteps() {
    return {
        type: ORDER.REFRESH_STEPS
    };
}

export function startNewOrder() {
    return {
        type: ORDER.START_NEW_ORDER
    };
}

export function initNewOrder() {
    return (dispatch, getState) => {
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.INIT_NEW_ORDER));

        initOrder()
            .then(payload => {
                const orderId = get(payload, ['data', 'id'], null);
                const data = {
                    id: orderId,
                    csrfToken: get(payload, ['data', 'csrfToken'], null),
                    supportedFileTypes: get(payload, ['cfg', 'allowTypes'], []),
                    supportedFileSize:
                        get(payload, ['cfg', 'limit'], 0) * 1024 * 1024, // MB
                    stripeKey: get(payload, ['cfg', 'stripeKey']),
                    paymentMethod: get(payload, ['data', 'paymentMethod']),
                    state: get(payload, ['data', 'state'])
                };

                dispatch(receiveData(ORDER.ON_NEW_ORDER_INITED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 1 - Create order',
                    status: 'failed',
                    userEmail,
                    requestId: e.requestId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function fetchOrder(orderId) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const currentStep = getState().getIn(['order', 'currentStep']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.FETCH_ORDER));

        getOrder(orderId, csrfToken)
            .then(payload => {
                const data = {
                    id: get(payload, ['data', 'id'], null),
                    supportedFileTypes: get(payload, ['cfg', 'allowTypes'], []),
                    supportedFileSize:
                        get(payload, ['cfg', 'limit'], 0) * 1024 * 1024, // MB
                    file: get(payload, ['data', 'resource', 'files'], [])[0],
                    assets: get(payload, ['data', 'resource', 'assets'], []),
                    quotation: get(payload, ['data', 'quotation'], {}),
                    targets: get(payload, ['data', 'language', 'targets']),
                    info: get(payload, ['data', 'info'], {}),
                    checkout: get(payload, ['data', 'language'], {}),
                    stripeKey: get(payload, ['cfg', 'stripeKey']),
                    paymentMethod: get(payload, ['data', 'paymentMethod']),
                    state: get(payload, ['data', 'state'])
                };

                dispatch(receiveData(ORDER.ON_ORDER_FETCHED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: `Step ${currentStep} - Get order`,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(startNewOrder());
            });
    };
}

export function uploadFile(orderId, files) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.UPLOAD_FILE));

        postFile(orderId, csrfToken, files)
            .then(payload => {
                const file = get(payload, ['data', 'resource', 'files'], [])[0];
                const data = {
                    file,
                    state: get(payload, ['data', 'state'])
                };

                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_1,
                    status: 'did',
                    userEmail,
                    orderId,
                    fileName: get(file, 'original')
                });

                dispatch(receiveData(ORDER.ON_FILE_UPLOADED, data)); // support one file uploading this time
            })
            .catch(e => {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_1,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.message || e.code
                });

                dispatch(receiveData(ORDER.ON_UPLOAD_FILE_FAILED, e));
            });
    };
}

export function downloadFile(orderId, fileName) {
    return (dispatch, getState) => {
        const csrf_token = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.DOWNLOAD_FILE));

        getFile(orderId, csrf_token, fileName)
            .then(payload => {
                let a = document.createElement('a');
                a.style = 'display: none';
                const blob = new Blob([payload], {
                    type: 'application/octet-stream'
                });
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 100);
                dispatch(receiveData(ORDER.ON_FILE_DOWNLOADED, payload));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Download file',
                    status: 'failed',
                    userEmail,
                    orderId,
                    fileName,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function getFileContent(orderId, fileName) {
    return (dispatch, getState) => {
        const csrf_token = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.GET_FILE_CONTENT));

        getFile(orderId, csrf_token, fileName)
            .then(payload => {
                const blob = new Blob([payload], {
                    type: 'application/octet-stream'
                });

                const fileReader = new FileReader();
                fileReader.addEventListener('loadend', function(e) {
                    dispatch(
                        receiveData(
                            ORDER.ON_FILE_CONTENT_GOT,
                            e.srcElement.result
                        )
                    );
                });
                fileReader.readAsText(blob);
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 2 - Get file',
                    status: 'failed',
                    userEmail,
                    orderId,
                    fileName,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function onFileSettingInput(settings) {
    return {
        type: ORDER.ON_FILE_SETTING_CONFIRMED,
        data: settings
    };
}

export function calculateFile(orderId, settings, sheetStartRows) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.CALCULATE_FILE));

        // Build request params
        const params = [];
        settings.forEach((sheetSettings, sheetIndex) => {
            const param = {
                sourceColumn: null,
                targetColumns: [],
                additionalColumns: [],
                sheetIndex: sheetIndex
            };
            const startRow = sheetStartRows[sheetIndex];

            sheetSettings.forEach((setting, colIndex) => {
                const key = setting.group || setting.id;

                switch (key) {
                    case '':
                    case undefined:
                    case 'notused':
                        break;

                    case 'sourceLanguage':
                        param.sourceColumn = {
                            lang: 'enUS',
                            name: 'English',
                            col: colIndex,
                            startRow
                        };
                        break;

                    case 'targetLanguages':
                        param.targetColumns.push({
                            lang: setting.id,
                            name: setting.name,
                            col: colIndex,
                            startRow
                        });
                        break;

                    default:
                        param.additionalColumns.push({
                            type: setting.id,
                            name: setting.name,
                            col: colIndex,
                            startRow
                        });
                }
            });

            if (param.sourceColumn) {
                params.push(param);
            }
        });

        postFileSettings(orderId, csrfToken, params)
            .then(payload => {
                const data = {
                    file: get(payload, ['data', 'resource', 'files'], [])[0],
                    quotation: get(payload, ['data', 'quotation'], {}),
                    targets: get(payload, ['data', 'language', 'targets']),
                    state: get(payload, ['data', 'state'])
                };

                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_2,
                    status: 'did',
                    userEmail,
                    orderId
                });

                dispatch(receiveData(ORDER.ON_FILE_CALCULATED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_2,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function calculateOrderPrice(orderId, settings, currency) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.CALCULATE_PRICE));

        const params = settings ? settings.map(element => element.id) : [];

        postInstanceQuote(orderId, csrfToken, { langs: params, currency })
            .then(payload => {
                const data = {
                    quotation: get(payload, ['data']) || { currency }
                };

                dispatch(receiveData(ORDER.ON_PRICE_CALCULATED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 3 - Calculate price',
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function checkoutOrder(orderId, settings, currency) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);
        const wordCount = getState().getIn([
            'order',
            'fileUploading',
            'data',
            'wordCount',
            'sourceWordCount'
        ]);

        dispatch(requestData(ORDER.CHECKOUT_ORDER));

        const params = {
            source: 'enUS',
            targets: settings.map(element => element.id),
            currency
        };

        postLanguageSettings(orderId, csrfToken, params)
            .then(payload => {
                const quotation = get(payload, ['data', 'quotation'], {});
                const data = {
                    quotation,
                    targets: get(payload, ['data', 'language', 'targets']),
                    checkout: get(payload, ['data', 'language'], {}),
                    state: get(payload, ['data', 'state'])
                };

                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_3,
                    status: 'did',
                    userEmail,
                    orderId,
                    total:
                        get(quotation, 'sumCost', 0) +
                        ` ${get(quotation, 'currency')}`,
                    wordCount
                });

                dispatch(receiveData(ORDER.ON_ORDER_CHECKOUT, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_3,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function setOrderCurrency(orderId, currency) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);
        const wordCount = getState().getIn([
            'order',
            'fileUploading',
            'data',
            'wordCount',
            'sourceWordCount'
        ]);

        dispatch(requestData(ORDER.CALCULATE_PRICE));

        postCurrencySettings(orderId, csrfToken, { currency })
            .then(payload => {
                const quotation = get(payload, ['data', 'quotation'], {});
                const data = {
                    quotation,
                    targets: get(payload, ['data', 'language', 'targets']),
                    checkout: get(payload, ['data', 'language'], {}),
                    state: get(payload, ['data', 'state'])
                };

                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_6,
                    userEmail,
                    orderId,
                    total:
                        get(quotation, 'sumCost', 0) +
                        ` ${get(quotation, 'currency')}`,
                    wordCount
                });

                dispatch(receiveData(ORDER.ON_ORDER_CURRENCY_CHANGED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_6,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function fillOrderInformation(orderId, info) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.CALCULATE_FILE));

        postOrderInformation(orderId, csrfToken, info)
            .then(payload => {
                const info = get(payload, ['data', 'info'], {});
                const data = {
                    info,
                    state: get(payload, ['data', 'state'])
                };

                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_4,
                    status: 'did',
                    userEmail,
                    orderId,
                    projectName: info.gameTitle
                });

                dispatch(receiveData(ORDER.ON_ORDER_INFORMATION_SAVED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_4,
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function uploadAssetFile(orderId, fileObject) {
    return (dispatch, getState) => {
        const csrfToken = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.UPLOAD_FILE));

        if (fileObject.file.size > MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
            dispatch(
                receiveData(ORDER.ON_FAILED, { code: 'FILE_SIZE_EXCEEDED' })
            );
            return;
        }

        postAssetFile(orderId, csrfToken, fileObject)
            .then(payload => {
                const data = {
                    assets: get(payload, ['data', 'resource', 'assets'], [])
                };

                dispatch(receiveData(ORDER.ON_ASSET_FILE_UPLOADED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 4 - Upload asset file',
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });

                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function downloadAssetFile({
    orderId,
    referOrderId,
    fileName,
    newFileName
}) {
    return (dispatch, getState) => {
        const csrf_token = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.DOWNLOAD_FILE));

        getAssetFile(orderId, csrf_token, fileName, referOrderId)
            .then(payload => {
                let a = document.createElement('a');
                const blob = new Blob([payload], {
                    type: 'application/octet-stream'
                });
                a.href = window.URL.createObjectURL(blob);
                a.download = newFileName || fileName;
                a.click();
                dispatch(receiveData(ORDER.ON_FILE_DOWNLOADED, payload));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 4 - Download asset file',
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function removeAssetFile(orderId, fileName) {
    return (dispatch, getState) => {
        const csrf_token = getState().getIn(['order', 'csrfToken']);
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(ORDER.CALCULATE_FILE));

        deleteAssetFile(orderId, csrf_token, fileName)
            .then(payload => {
                const data = {
                    assets: get(payload, ['data', 'resource', 'assets'], [])
                };

                dispatch(receiveData(ORDER.ON_ASSET_FILE_REMOVED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Step 4 - Remove asset file',
                    status: 'failed',
                    userEmail,
                    orderId,
                    errorCode: e.code
                });
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function fetchAllLanguages() {
    return (dispatch, getState) => {
        const userEmail = getState().getIn(['auth', 'user', 'email']);

        dispatch(requestData(COMMON.FETCH_LANGUAGES));

        fetchLanguages()
            .then(payload => {
                let data = [];
                if (payload.data) {
                    data = payload.data.map(element => ({
                        ...element,
                        id: element.lang,
                        name: element.title,
                        price: element.price,
                        recommended: element.recommended
                    }));

                    data.sort((a, b) => {
                        const lang1 = a.name.toLowerCase();
                        const lang2 = b.name.toLowerCase();

                        if (a.recommended && !b.recommended) {
                            return -1;
                        }
                        if (!a.recommended && b.recommended) {
                            return 1;
                        }
                        if (lang1 < lang2) {
                            return -1;
                        }
                        if (lang1 > lang2) {
                            return 1;
                        }
                        return 0;
                    });
                }
                dispatch(receiveData(COMMON.ON_LANGUAGES_FETCHED, data));
            })
            .catch(e => {
                notifyToSlack({
                    stepName: 'Get language list',
                    status: 'failed',
                    userEmail,
                    requestId: e.requestId,
                    errorCode: e.code || e.message
                });
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export const lockPayment = isLock => {
    return {
        type: PAYMENT.LOCK_PAYMENT,
        isLock
    };
};

export const setupPayment = () => (dispatch, getState) => {
    const csrfToken = getState().getIn(['order', 'csrfToken']);
    const orderId = getState().getIn(['order', 'id']);
    const userEmail = getState().getIn(['auth', 'user', 'email']);

    return payApi
        .setupPayment(orderId, csrfToken)
        .then(payload =>
            dispatch(receiveData(PAYMENT.ON_AUTHORIZE_PAYMENT, payload.data))
        )
        .catch(e => {
            dispatch(receiveData(ORDER.ON_FAILED, e));

            notifyToSlack({
                stepName: 'Step 5 - Setup payment',
                status: 'failed',
                userEmail,
                orderId,
                errorCode: `${e.code} - ${JSON.stringify(e.details || '')}`
            });
        });
};

export const executePayment = (token, method, params) => (
    dispatch,
    getState
) => {
    const csrfToken = getState().getIn(['order', 'csrfToken']);
    const orderId = getState().getIn(['order', 'id']);
    const userEmail = getState().getIn(['auth', 'user', 'email']);
    const wordCount = getState().getIn([
        'order',
        'fileUploading',
        'data',
        'wordCount',
        'sourceWordCount'
    ]);
    const total = getState().getIn(['order', 'quotation', 'data', 'totalCost']);
    const currency = getState().getIn([
        'order',
        'quotation',
        'data',
        'currency'
    ]);

    return payApi
        .executePayment(orderId, csrfToken, token, method, params)
        .then(payload => {
            if (payload.requires_action) {
                dispatch(
                    receiveData(PAYMENT.ON_PAYMENT_INTENT_CREATED, payload)
                );
            } else {
                notifyToSlack({
                    stepName: ORDER_STEPS.STEP_5,
                    status: 'done',
                    userEmail,
                    orderId,
                    wordCount,
                    total: total + ` ${currency}`,
                    paymentMethod: method
                });
                submitActionEventToGtm('Paid');
                submitPageViewEventToGtm(ORDER_STEPS.STEP_END, {
                    userEmail,
                    wordCount,
                    total,
                    currency,
                    paymentMethod: method
                });
                submitActionEvent('paid', { email: userEmail });

                dispatch(
                    receiveData(PAYMENT.ON_EXECUTED_PAYMENT, payload.data)
                );
            }
        })
        .catch(e => {
            dispatch(receiveData(ORDER.ON_FAILED, e));

            notifyToSlack({
                stepName: ORDER_STEPS.STEP_5,
                status: 'failed',
                userEmail,
                orderId,
                paymentMethod: method,
                errorCode: e.code
            });
        });
};

export function downloadQuote(orderId, fileName) {
    return (dispatch, getState) => {
        const csrf_token = getState().getIn(['order', 'csrfToken']);

        dispatch(requestData(ORDER.DOWNLOAD_FILE));

        getQuoteFile(orderId, csrf_token)
            .then(payload => {
                let a = document.createElement('a');
                const blob = new Blob([payload], {
                    type: 'application/octet-stream'
                });
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.click();
                dispatch(receiveData(ORDER.ON_FILE_DOWNLOADED, payload));
            })
            .catch(e => {
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { func, string, number, object, array } from 'prop-types';
import get from 'lodash/get';

import {
    UploadBox,
    CheckboxItem,
    TextInput,
    InputSelect,
    Select,
    DateTimeInput,
    toJS
} from 'components';

import { getFileExt } from '../common/fileUtils';
import {
    fillOrderInformation,
    uploadAssetFile,
    downloadAssetFile,
    removeAssetFile
} from './actions';
import { fetchOrderList } from '../customer/actions';
import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';
//import { MAX_UPLOAD_FILE_SIZE } from './constants';
import { MIN_PAYMENT_AMOUNT } from './constants';
import FileDownload from './FileDownload';

import './OrderInformation-style.css';

const ASSET_FILE_PREFIXS = {
    originalLanguage: '[original]',
    isLegacyTranslation: '[legacy]',
    isGlossary: '[glossary]',
    isStyleGuide: '[styleguide]',
    isScreenshots: '[screenshots]'
};

const TARGET_AGE_OPTIONS = [
    { value: 3, label: '3+' },
    { value: 7, label: '7+' },
    { value: 12, label: '12+' },
    { value: 16, label: '16+' },
    { value: 18, label: '18+' }
];
const GAME_CATEGORY_OPTIONS = [
    { value: 'Action', label: 'Action' },
    { value: 'Adult/Hentai', label: 'Adult/Hentai' },
    { value: 'Adventure', label: 'Adventure' },
    { value: 'Arcade', label: 'Arcade' },
    { value: 'Battle Royale', label: 'Battle Royale' },
    { value: 'Board', label: 'Board' },
    { value: 'Card', label: 'Card' },
    { value: 'Casino', label: 'Casino' },
    { value: 'Casual', label: 'Casual' },
    { value: 'City Building', label: 'City Building' },
    { value: 'Dice', label: 'Dice' },
    { value: 'Educational', label: 'Educational' },
    { value: 'Electronic Sports', label: 'Electronic Sports' },
    { value: 'Family', label: 'Family' },
    { value: 'Fantasy', label: 'Fantasy' },
    { value: 'Fighting', label: 'Fighting' },
    { value: 'FPS', label: 'FPS' },
    { value: 'Horror', label: 'Horror' },
    { value: 'Kids', label: 'Kids' },
    { value: 'Match 3', label: 'Match 3' },
    { value: 'MMO RPG', label: 'MMO RPG' },
    { value: 'MOBA', label: 'MOBA' },
    { value: 'Music', label: 'Music' },
    { value: 'Platformer', label: 'Platformer' },
    { value: 'Point & Click', label: 'Point & Click' },
    { value: 'Puzzle', label: 'Puzzle' },
    { value: 'Racing', label: 'Racing' },
    { value: 'Rogue - like', label: 'Rogue - like' },
    { value: 'RPG', label: 'RPG' },
    { value: 'RTS', label: 'RTS' },
    { value: 'Sandbox', label: 'Sandbox' },
    { value: 'Sci - Fi', label: 'Sci - Fi' },
    { value: 'Shooter', label: 'Shooter' },
    { value: 'Simulation', label: 'Simulation' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Story - driven', label: 'Story - driven' },
    { value: 'Strategy', label: 'Strategy' },
    { value: 'Survival', label: 'Survival' },
    { value: 'Trivia', label: 'Trivia' },
    { value: 'Turn - based strategy', label: 'Turn - based strategy' },
    { value: 'Visual Novel', label: 'Visual Novel' },
    { value: 'Word', label: 'Word' }
];

const PLATFORMS = [
    { value: 'iOS', label: 'iOS' },
    { value: 'Android', label: 'Android' },
    { value: 'PS4', label: 'PS4' },
    { value: 'PS5', label: 'PS5' },
    { value: 'Xbox One', label: 'Xbox One' },
    { value: 'Xbox Series X', label: 'Xbox Series X' },
    { value: 'PC', label: 'PC' },
    { value: 'Stadia', label: 'Stadia' },
    { value: 'Switch', label: 'Switch' },
    { value: 'Nintendo DS', label: 'Nintendo DS' }
];

const SWEARING_OPTIONS = [
    { value: 'No', label: 'No' },
    { value: 'Mild (+12)', label: 'Mild (+12)' },
    { value: 'Strong (+16)', label: 'Strong (+16)' },
    { value: 'Realistic (+18)', label: 'Realistic (+18)' }
];

const LANGUAGES = [
    { value: 'English', label: 'English' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'German', label: 'German' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Other', label: '[Other]' }
];

const SELECT_STYLES = {
    multiValue: (styles, { data }) => ({
        ...styles,
        border: '1px solid #a1b0b3',
        borderRadius: '20px',
        paddingLeft: '6px',
        paddingRight: '6px'
        //backgroundColor: '#fff'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        opacity: '0.4',
        ':hover': {
            backgroundColor: data.color,
            opacity: '1'
        }
    })
};

class OrderInformation extends React.PureComponent {
    static propTypes = {
        orderId: string,
        sourceWordCount: number,
        info: object,
        assetFiles: array,
        fillOrderInformation: func
    };

    state = this.props.info || {};

    onInput = (id, text) => {
        this.setState({
            [id]: text
        });
    };

    onCheck = (id, value) => {
        this.setState({
            [id]: value
        });
    };

    onGameReleaseDateInput = date => {
        this.setState({
            gameReleaseDate: date
        });
    };

    onPlatformsChange = values => {
        this.setState({
            platforms: values.map(option => option.value)
        });
    };

    onGameCategoriesChange = values => {
        this.setState({
            gameCategories: values.map(option => option.value)
        });
    };

    onNoteInput = text => {
        this.setState({
            note: text
        });
    };

    buildDuplicatedFileName = (fileName, existFileNames, count) => {
        if (!existFileNames.includes(fileName)) {
            return fileName;
        }

        const fileExt = getFileExt(fileName);
        //replace(/ \(.*\)+\..*/,'')
        const newName =
            fileName.replace(/ \(.\)/, '').replace(`${fileExt}`, '') +
            ` (${count + 1})${fileExt}`;

        return this.buildDuplicatedFileName(newName, existFileNames, count + 1);
    };

    onFileInput = (assetId, files) => {
        const { orderId, assetFiles, uploadAssetFile } = this.props;

        uploadAssetFile(orderId, {
            name: this.buildDuplicatedFileName(
                `${assetId}${files[0].name}`,
                assetFiles || [],
                0
            ),
            file: files[0]
        });
    };

    onWebPageInput = value => {
        this.setState({
            webPage: value
        });
    };

    onContinueClick = () => {
        const { orderId, fillOrderInformation } = this.props;
        const info = {
            version: 2
        };

        Object.keys(this.state).forEach(key => {
            if (this.state[key] !== undefined) {
                info[key] = this.state[key];
            }
        });

        fillOrderInformation(orderId, info);
    };

    isInputDone = () => {
        const {
            gameTitle,
            isGameReleased,
            isTranslateGameTitle,
            targetAge,
            platforms,
            gameCategories,
            originalLanguage,
            isRemainTerms,
            isAdaptation,
            isStringLengthLimit,
            swearing,
            isDialoguesInOrder,
            characterAddress,
            messagesAddress,
            targetGender,
            isSpecialCharacter
        } = this.state;

        return (
            !!gameTitle &&
            !!targetAge &&
            isTranslateGameTitle !== undefined &&
            isGameReleased !== undefined &&
            platforms &&
            platforms.length > 0 &&
            gameCategories &&
            gameCategories.length > 0 &&
            !!originalLanguage &&
            isRemainTerms !== undefined &&
            isAdaptation !== undefined &&
            isStringLengthLimit !== undefined &&
            !!swearing &&
            isDialoguesInOrder !== undefined &&
            !!characterAddress &&
            !!messagesAddress &&
            !!targetGender &&
            isSpecialCharacter !== undefined
        );
    };

    buildAssetFileList = filterAssetId => {
        const {
            orderList = [],
            orderId,
            assetFiles,
            downloadAssetFile,
            removeAssetFile
        } = this.props;

        const { originalOrderUid } = this.state;
        const list = [];

        const referOrder = orderList
            ? orderList.find(order => order.uid === originalOrderUid) || {}
            : {};

        referOrder.assets &&
            referOrder.assets.forEach(fileName => {
                if (fileName.includes(filterAssetId)) {
                    const downloadFile = () => {
                        downloadAssetFile({
                            orderId,
                            referOrderId: originalOrderUid,
                            fileName,
                            newFileName: fileName.replace(filterAssetId, '')
                        });
                    };

                    list.push(
                        <FileDownload
                            key={`refer-asset-file-${fileName}`}
                            fileName={fileName.replace(filterAssetId, '')}
                            onDownload={downloadFile}
                        />
                    );
                }
            });

        assetFiles &&
            assetFiles.forEach(fileName => {
                if (fileName.includes(filterAssetId)) {
                    const downloadFile = () => {
                        downloadAssetFile({
                            orderId,
                            fileName,
                            newFileName: fileName.replace(filterAssetId, '')
                        });
                    };
                    const removeFile = () => {
                        removeAssetFile(orderId, fileName);
                    };

                    list.push(
                        <FileDownload
                            key={`asset-file-${fileName}`}
                            fileName={fileName.replace(filterAssetId, '')}
                            onDownload={downloadFile}
                            onRemove={removeFile}
                        />
                    );
                }
            });

        return <div className="asset-files">{list}</div>;
    };

    onExistingSelect = option => {
        const { orderList } = this.props;
        const referOrder =
            orderList.find(order => order.uid === option.value) || {};
        const referInfo = referOrder.info || {};

        this.setState({
            isTranslateGameTitle: undefined,
            gameCategories: undefined,
            targetAge: undefined,
            platforms: undefined,
            isGameReleased: undefined,
            gameReleaseDate: undefined,
            originalLanguage: undefined,
            isRemainTerms: undefined,
            remainTerms: undefined,
            isSpecialCharacter: undefined,
            specialCharactersNote: undefined,
            swearing: undefined,
            isStringLengthLimit: undefined,
            stringLengthPriority: undefined,
            stringLengthLimitNote: undefined,
            isAdaptation: undefined,
            adaptationNote: undefined,
            isDialoguesInOrder: undefined,
            dialoguesInOrderNote: undefined,
            characterAddress: undefined,
            messagesAddress: undefined,
            targetGender: undefined,
            note: undefined,
            webPage: undefined,
            ...referInfo,
            originalOrderUid: referInfo.originalOrderUid || referOrder.uid,
            originalOrderId: referInfo.originalOrderId || referOrder.id
        });
    };

    onGameTitleInput = text => {
        this.setState({
            originalOrderUid: '',
            originalOrderId: '',
            gameTitle: text
        });
    };

    componentDidMount() {
        const { user, fetchOrderList } = this.props;

        if (user) {
            fetchOrderList({ limit: 100 });
        }
    }

    componentDidUpdate(prevProps) {
        const { user, fetchOrderList } = this.props;

        if (!prevProps.user && !!user) {
            fetchOrderList({ limit: 100 });
        }
    }

    render() {
        const { sourceWordCount, quotation, orderList } = this.props;
        const currency =
            CURRENCIES[get(quotation, 'currency')] || DEFAULT_CURRENCY;
        const {
            gameTitle,
            isGameReleased,
            gameReleaseDate,
            isTranslateGameTitle,
            targetAge,
            isRemainTerms,
            remainTerms,
            isStringLengthLimit,
            stringLengthLimitNote,
            swearing,
            characterAddress,
            messagesAddress,
            targetGender,
            originalLanguage,
            platforms = [],
            isAdaptation,
            adaptationNote,
            stringLengthPriority,
            isDialoguesInOrder,
            dialoguesInOrderNote,
            isSpecialCharacter,
            specialCharactersNote,
            //gameCategory,
            gameCategories,
            note,
            webPage
        } = this.state;

        const gameOptions = orderList
            ? orderList.map(game => ({
                  label: game.project,
                  value: game.uid
              }))
            : [];
        gameOptions.sort((a, b) => {
            const value1 = a.label.toLowerCase();
            const value2 = b.label.toLowerCase();

            if (value1 < value2) {
                return -1;
            }
            if (value1 > value2) {
                return 1;
            }
            return 0;
        });

        return (
            <div className="step-page provide-order-info">
                <div className="page-title">Add information</div>
                <div className="page-note">
                    Providing your game info will help translators understand
                    requirements and benefit overall Localization quality.
                </div>
                <div className="info-container">
                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">Game title</span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <InputSelect
                                    value={gameTitle}
                                    options={gameOptions}
                                    onSelectChange={this.onExistingSelect}
                                    onInputChange={this.onGameTitleInput}
                                    placeholder={
                                        gameOptions.length
                                            ? 'Your new game title or select existing one'
                                            : 'Your game title'
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Translate game title?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false },
                                        {
                                            label: `Follow linguist's advice`,
                                            value: 'follow'
                                        }
                                    ]}
                                    value={isTranslateGameTitle}
                                    onChange={({ value }) =>
                                        this.onCheck(
                                            'isTranslateGameTitle',
                                            value
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Game link(s):
                                </span>

                                <TextInput
                                    value={webPage}
                                    placeholder="http://your-game-page.com"
                                    rows={2}
                                    onChange={text =>
                                        this.onInput('webPage', text)
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Game already released?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    value={isGameReleased}
                                    onChange={({ value }) =>
                                        this.onCheck('isGameReleased', value)
                                    }
                                />
                            </label>
                            {isGameReleased === false && (
                                <label className="ml-2">
                                    <span className="label-text">
                                        Release Date:
                                    </span>
                                    <div className="date-input">
                                        <DateTimeInput
                                            value={gameReleaseDate}
                                            placeholder="mm-dd-yyyy"
                                            onChange={value =>
                                                this.onCheck(
                                                    'gameReleaseDate',
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">Age rating:</span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>

                                <Select
                                    options={TARGET_AGE_OPTIONS}
                                    value={targetAge}
                                    onChange={({ value }) =>
                                        this.onCheck('targetAge', value)
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">Platform(s):</span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>

                                <Select
                                    className="multi"
                                    styles={SELECT_STYLES}
                                    options={PLATFORMS}
                                    isMulti
                                    value={platforms}
                                    onChange={this.onPlatformsChange}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">Swearing:</span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={SWEARING_OPTIONS}
                                    value={swearing}
                                    onChange={({ value }) =>
                                        this.onCheck('swearing', value)
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">Genre(s):</span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    className="multi"
                                    styles={SELECT_STYLES}
                                    options={GAME_CATEGORY_OPTIONS}
                                    isMulti
                                    value={gameCategories}
                                    onChange={this.onGameCategoriesChange}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Original game language:
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={LANGUAGES}
                                    value={
                                        !LANGUAGES.find(
                                            lang =>
                                                lang.value === originalLanguage
                                        ) && originalLanguage
                                            ? 'Other'
                                            : originalLanguage
                                    }
                                    onChange={({ value }) => {
                                        this.onCheck('originalLanguage', value);
                                    }}
                                />
                            </label>
                            {((!LANGUAGES.find(
                                lang => lang.value === originalLanguage
                            ) &&
                                originalLanguage) ||
                                originalLanguage === 'Other') && (
                                <label>
                                    <span className="label-text">
                                        Language:
                                    </span>
                                    <div className="text-input">
                                        <TextInput
                                            value={
                                                originalLanguage === 'Other'
                                                    ? ''
                                                    : originalLanguage
                                            }
                                            placeholder="Please type the language"
                                            onChange={text =>
                                                this.onInput(
                                                    'originalLanguage',
                                                    !text ? 'Other' : text
                                                )
                                            }
                                        />
                                    </div>
                                </label>
                            )}

                            <UploadBox
                                id="upload-box-originalLanguage"
                                name="upload-trans-source"
                                onNewFiles={files =>
                                    this.onFileInput(
                                        ASSET_FILE_PREFIXS.originalLanguage,
                                        files
                                    )
                                }
                                disabled={false}
                            >
                                Upload for reference
                            </UploadBox>
                            {this.buildAssetFileList(
                                ASSET_FILE_PREFIXS.originalLanguage
                            )}
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    List of terms that should remain
                                    untranslated (if any)?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>

                                <Select
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    value={remainTerms ? true : isRemainTerms}
                                    onChange={({ value }) => {
                                        this.onCheck('isRemainTerms', value);

                                        if (value === false) {
                                            this.onCheck('remainTerms', '');
                                        }
                                    }}
                                />
                            </label>
                            {isRemainTerms && (
                                <p>
                                    <label>
                                        <span className="label-text">
                                            Retain following terms in original
                                            language:
                                        </span>

                                        <TextInput
                                            placeholder="List:"
                                            rows={4}
                                            onChange={text =>
                                                this.onInput(
                                                    'remainTerms',
                                                    text
                                                )
                                            }
                                            value={remainTerms}
                                        />
                                    </label>
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Are there legacy translations?
                                </span>
                            </label>
                            <UploadBox
                                id="upload-box-isLegacyTranslation"
                                name="upload-trans-source"
                                onNewFiles={files =>
                                    this.onFileInput(
                                        ASSET_FILE_PREFIXS.isLegacyTranslation,
                                        files
                                    )
                                }
                                disabled={false}
                            >
                                Upload for reference
                            </UploadBox>
                            {this.buildAssetFileList(
                                ASSET_FILE_PREFIXS.isLegacyTranslation
                            )}
                        </div>

                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Is there a glossary?
                                </span>
                            </label>
                            <UploadBox
                                id="upload-box-isGlossary"
                                name="upload-trans-source"
                                onNewFiles={files =>
                                    this.onFileInput(
                                        ASSET_FILE_PREFIXS.isGlossary,
                                        files
                                    )
                                }
                                disabled={false}
                            >
                                Upload for reference
                            </UploadBox>
                            {this.buildAssetFileList(
                                ASSET_FILE_PREFIXS.isGlossary
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Is there a style guide?
                                </span>
                            </label>
                            <UploadBox
                                id="upload-box-isStyleGuide"
                                name="upload-trans-source"
                                onNewFiles={files =>
                                    this.onFileInput(
                                        ASSET_FILE_PREFIXS.isStyleGuide,
                                        files
                                    )
                                }
                                disabled={false}
                            >
                                Upload for reference
                            </UploadBox>
                            {this.buildAssetFileList(
                                ASSET_FILE_PREFIXS.isStyleGuide
                            )}
                        </div>
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Screenshots/Videos:
                                </span>
                            </label>
                            <UploadBox
                                id="upload-box-isScreenshots"
                                name="upload-trans-source"
                                onNewFiles={files =>
                                    this.onFileInput(
                                        ASSET_FILE_PREFIXS.isScreenshots,
                                        files
                                    )
                                }
                                disabled={false}
                            >
                                Upload for reference
                            </UploadBox>
                            {this.buildAssetFileList(
                                ASSET_FILE_PREFIXS.isScreenshots
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Are dialogues in order? Is info on who's the
                                    speaker available (gender issues)?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false },
                                        { label: 'N/A', value: 'na' }
                                    ]}
                                    value={isDialoguesInOrder}
                                    onChange={({ value }) =>
                                        this.onCheck(
                                            'isDialoguesInOrder',
                                            value
                                        )
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    Dialogue comments:
                                </span>
                                <p className="note-text">
                                    Please always provide speakers name and
                                    gender for each dialogue string,
                                    <br />
                                    as well as any information about the age
                                    group of the characters, as <br />
                                    this affects the translation style in some
                                    languages.
                                </p>
                                <TextInput
                                    placeholder="Comments:"
                                    rows={3}
                                    onChange={text =>
                                        this.onInput(
                                            'dialoguesInOrderNote',
                                            text
                                        )
                                    }
                                    value={dialoguesInOrderNote}
                                />
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    How do characters address each other?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        {
                                            label: 'Informal',
                                            value: 'informal'
                                        },
                                        { label: 'Formal', value: 'formal' },
                                        {
                                            label: `Follow linguist's advice`,
                                            value: 'follow'
                                        }
                                    ]}
                                    value={characterAddress}
                                    onChange={({ value }) =>
                                        this.onCheck('characterAddress', value)
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    How do the game messages address the player?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        {
                                            label: 'Informal',
                                            value: 'informal'
                                        },
                                        { label: 'Formal', value: 'formal' },
                                        {
                                            label: `Follow linguist's advice`,
                                            value: 'follow'
                                        }
                                    ]}
                                    value={messagesAddress}
                                    onChange={({ value }) =>
                                        this.onCheck('messagesAddress', value)
                                    }
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    The gender of the player character can be:
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        {
                                            label: 'Female',
                                            value: 'female'
                                        },
                                        { label: 'Male', value: 'male' },
                                        {
                                            label: 'Both',
                                            value: 'both'
                                        }
                                    ]}
                                    value={targetGender}
                                    onChange={({ value }) =>
                                        this.onCheck('targetGender', value)
                                    }
                                />
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    Are adaptations required <br /> (currency,
                                    measurements, date conversion, references to
                                    local culture, etc.)?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    value={isAdaptation}
                                    onChange={({ value }) =>
                                        this.onCheck('isAdaptation', value)
                                    }
                                />
                            </label>
                            {isAdaptation && (
                                <label>
                                    <span className="label-text">
                                        Adaptation rules
                                    </span>
                                    <TextInput
                                        placeholder="Describe rules:"
                                        rows={3}
                                        disabled={
                                            isAdaptation === undefined ||
                                            isAdaptation === false
                                        }
                                        onChange={text =>
                                            this.onInput('adaptationNote', text)
                                        }
                                        value={adaptationNote}
                                    />
                                </label>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <label>
                                <span className="label-text">
                                    Is there a string length limitation?
                                    <br />
                                    (standard is 20-30% longer than English)
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        {
                                            label: 'Yes',
                                            value: true
                                        },
                                        { label: 'No', value: false }
                                    ]}
                                    value={isStringLengthLimit}
                                    onChange={({ value }) =>
                                        this.onCheck(
                                            'isStringLengthLimit',
                                            value
                                        )
                                    }
                                />

                                {isStringLengthLimit && (
                                    <div className="text-input flex-centered">
                                        <CheckboxItem
                                            label="Prioritize Length"
                                            value="stringLengthPriority"
                                            checked={
                                                isStringLengthLimit === true &&
                                                stringLengthPriority ===
                                                    'length'
                                            }
                                            disabled={
                                                isStringLengthLimit ===
                                                    undefined ||
                                                isStringLengthLimit === false
                                            }
                                            onChange={({ value }) =>
                                                this.onCheck(value, 'length')
                                            }
                                        />
                                        <CheckboxItem
                                            label="Prioritize Quality"
                                            value="stringLengthPriority"
                                            checked={
                                                isStringLengthLimit === true &&
                                                stringLengthPriority ===
                                                    'quality'
                                            }
                                            disabled={
                                                isStringLengthLimit ===
                                                    undefined ||
                                                isStringLengthLimit === false
                                            }
                                            onChange={({ value }) =>
                                                this.onCheck(value, 'quality')
                                            }
                                        />
                                    </div>
                                )}

                                {isStringLengthLimit && (
                                    <div className="flex-centered">
                                        <TextInput
                                            placeholder="Describe rules:"
                                            rows={2}
                                            disabled={
                                                isStringLengthLimit === false ||
                                                isStringLengthLimit ===
                                                    undefined
                                            }
                                            onChange={text =>
                                                this.onInput(
                                                    'stringLengthLimitNote',
                                                    text
                                                )
                                            }
                                            value={stringLengthLimitNote}
                                        />
                                    </div>
                                )}
                            </label>
                        </div>
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    Does the game support special MS characters
                                    <br />
                                    such as non-breaking space, en dash (—),
                                    curly quotes, etc.?
                                </span>
                                <span className="required-text">
                                    {' '}
                                    (Required)
                                </span>
                                <Select
                                    options={[
                                        {
                                            label: 'Yes',
                                            value: true
                                        },
                                        { label: 'No', value: false },
                                        {
                                            label: `I don't know`,
                                            value: 'na'
                                        }
                                    ]}
                                    value={isSpecialCharacter}
                                    onChange={({ value }) =>
                                        this.onCheck(
                                            'isSpecialCharacter',
                                            value
                                        )
                                    }
                                />
                                {isSpecialCharacter === 'na' && false && (
                                    <TextInput
                                        placeholder="Use it or not?"
                                        onChange={text =>
                                            this.onInput(
                                                'specialCharactersNote',
                                                text
                                            )
                                        }
                                        value={specialCharactersNote}
                                    />
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8">
                            <label>
                                <span className="label-text">
                                    Other reference material
                                    <br />
                                    (extra links, game access keys, builds,
                                    wiki, extra context, descriptions, etc.):
                                </span>
                                <div className="text-input flex-centered">
                                    <TextInput
                                        rows={5}
                                        inputClass="w-100"
                                        onChange={this.onNoteInput}
                                        value={note}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <div className="total-section row container-full">
                        <div className="section count col-md-2">
                            <div className="bold-text">Source word count</div>
                            <div className="value">{sourceWordCount}</div>
                        </div>
                        <div className="col-md-7" />
                        <div className="total col-md-3">
                            <div className="sum">
                                <span className="title bold-text">Total </span>
                                <span className="value">
                                    {get(currency, 'ICON')}{' '}
                                    {get(quotation, 'sumCost', 0)}
                                </span>
                            </div>
                            <div className="note">VAT not included</div>
                            <div className="note">{`Minimum order amount is ${get(
                                currency,
                                'ICON'
                            )} ${get(
                                quotation,
                                'minimumFee',
                                MIN_PAYMENT_AMOUNT
                            )}`}</div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-next"
                            disabled={!this.isInputDone()}
                            onClick={this.onContinueClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const sourceWordCount = state.getIn([
        'order',
        'fileUploading',
        'data',
        'wordCount',
        'sourceWordCount'
    ]);

    return {
        sourceWordCount,
        assetFiles: state.getIn(['order', 'fileUploading', 'assets']),
        orderList: state.getIn(['customer', 'orders', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fillOrderInformation,
            uploadAssetFile,
            downloadAssetFile,
            removeAssetFile,
            fetchOrderList
        },
        dispatch
    );
};

export default DragDropContext(HTML5Backend)(
    connect(mapStateToProps, mapDispatchToProps)(toJS(OrderInformation))
);

import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';
import App from './App';
import AppPage from './AppPage';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configStore';
import { fromJS } from 'immutable';
import { loadState, saveState } from './common/localStorage';

import TagManager from 'react-gtm-module';

const persistedState = fromJS(loadState());
const store = configureStore(persistedState);

store.subscribe(
    throttle(() => {
        const state = store.getState();

        saveState({
            auth: {
                user: state.getIn(['auth', 'user']),
                modal: {}
            },
            order: {
                id: state.getIn(['order', 'id']),
                csrfToken: state.getIn(['order', 'csrfToken']),
                currentStep: state.getIn(['order', 'currentStep']),
                fileUploading: {},
                checkout: {},
                languages: {}
            }
        });
    }, 1000)
);

if (
    window.location.href.includes('/payment') ||
    window.location.href.includes('/delivery') ||
    window.location.href.includes('/confirmation') ||
    window.location.href.includes('/offers')
) {
    ReactDOM.render(<AppPage store={store} />, document.getElementById('root'));
} else {
    // Google Tag Manager
    const tagManagerArgs = {
        gtmId: 'GTM-T5ZP97X6'
    };
    TagManager.initialize(tagManagerArgs);

    ReactDOM.render(<App store={store} />, document.getElementById('root'));
}

unregister();

import { AGILE_CRM_VAR } from './const';

/*
function onCRMContactCreated(contact) {
    var tags = 'Source Inbound, Registered LocalizeDirect, Prospect';

    window._agile.set_email(contact.email);
    window._agile.add_tag(tags, {
        success: function() {},
        error: function() {}
    });
}
*/

function readTrafficCookies() {
    try {
        const cookies = document.cookie ? document.cookie.split(';') : [];
        const gCookies = cookies.filter(cookie => {
            return (
                cookie.includes('_ga') ||
                cookie.includes('initialTrafficSource') ||
                cookie.includes('hubspotutk') ||
                cookie.includes('_ld_')
            );
        });
        return gCookies.join(';');
    } catch {
        return '';
    }
}

export function submitActionEvent(eventName, userInfo) {
    // AgileCRM no longer used
    /*if (window._agile && email) {
        const contact = {};
        contact.email = email;

        window._agile.create_contact(contact, {
            success: function(data) {
                onCRMContactCreated(contact);
            },
            error: function(data) {
                if (data.error.search(/duplicate/i) !== -1) {
                    onCRMContactCreated(contact);
                }
            }
        });
    }*/

    try {
        const contact = {
            event: eventName
        };
        contact.email = userInfo.email;
        contact.first_name = userInfo.firstName;
        contact.last_name = userInfo.lastName;
        contact.company = userInfo.companyName;
        contact.country = userInfo.country;
        contact.state = userInfo.state;
        contact.traffic = readTrafficCookies();

        fetch(`${AGILE_CRM_VAR.AGILE_API_URL}/track`, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(contact)
        })
            .then(response => response.json())
            .catch(err => {});
    } catch {
        return true;
    }
}

export function loadCRMScripts() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = '_agile_min_js';
    script.async = true;
    script.src = AGILE_CRM_VAR.SRC;
    document.body.appendChild(script);

    window.Agile_API = window.Agile_API || {};
    window.Agile_API.on_after_load = function() {
        window._agile.set_account(
            AGILE_CRM_VAR.KEY,
            AGILE_CRM_VAR.DOMAIN,
            false
        );

        if (AGILE_CRM_VAR.WHITE_LIST) {
            window._agile_set_whitelist(AGILE_CRM_VAR.WHITE_LIST);
        }
        window._agile.track_page_view();
        //_agile.set_tracking_domain('{{ $.Site.Params.siteDomain }}');
        window._agile_execute_web_rules();
    };
}
